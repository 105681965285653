@import '../../styles/abstracts/mixins';

.header {
	&__avatar {
		cursor: pointer;
	}

	&__active-route {
		color: var(--active-link-color) !important;
		pointer-events: none;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background-color: var(--active-link-color);
			width: 100%;
			height: 0.3rem;
		}
	}

	&__active-side-route {
		color: var(--active-link-color) !important;
		pointer-events: none;
	}

	&__links-list {
		display: flex;
		list-style-type: none;
		margin-left: 1rem;

		&--side {
			align-items: center;
		}
	}

	&__link {
		display: block;
		text-transform: uppercase;
		text-align: center;
		color: inherit;
		position: relative;

		&,
		& * {
			transition: 0.3s;
		}

		&:hover {
			color: var(--header-color-link);
			background-color: var(--header-background-color-link) !important ;
		}
	}

	&__user-email {
		margin-right: 2rem;
	}

  &__side-dropdown-menu {
    width: 20rem;
    padding: 0 !important;
    border:1px solid var(--header-border-color-side-dropdown-menu) !important;

    .ant-dropdown-menu-item-divider {
      margin: 0;
    }
  }
}
