// DON'T FORGET FILL app/constants/styles file if you add new variable
:root {
	--base-white: #fff;
	--base-black: #000;
	--base-red: #e11;
	--auth-container-background: #e9e6e6;

  --base-mandy: #ec536c;
  --base-lima: #52c41a;
  --base-shakespeare: #44a2d2;
  --base-oxford-blue: #2d3b48;

	--antd-active-background: rgb(35, 144, 255); // TODO need change it
	--icon-colors: rgba(0, 0, 0, 0.25);

	// button // TODO make theme dependent
	--disabled-button-color: rgba(0, 0, 0, 0.25);
	--disabled-button-background-color: #f5f5f5;
	--disabled-button-border-color: #d9d9d9;
}
