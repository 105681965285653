html {
	font-size: 62.5% !important;

	@include breakpoint(lg) {
		font-size: 56.25% !important;
	}
	@include breakpoint(md) {
		font-size: 50% !important;
	}
	@include breakpoint(sm) {
		font-size: 62.5% !important;
	}
}

body {
	background: var(--background-color);
}
