@import '../../styles/abstracts/mixins';

.ant-table-body {
  background: var(--background-color) !important;
  border-left: 1px solid var(--table-border-color);
  border-top: 0 !important;
  border-right: 0 !important;
  overflow-x: unset !important;

  @include breakpoint(lg) {
    overflow-x: scroll !important;
  }

  > table {
    border: none !important;
  }
}

.ant-table-row {
  .anticon {
    color: var(--text-color) !important;
  }
}

.ant-table-tbody {
  tr > td {
    border-bottom: 1px solid var(--table-border-color) !important;
    background: var(--background-color) !important;
  }
}

.ant-table-thead {
  tr {
    height: 5.125rem;
    border-bottom: 1px solid var(--table-border-color) !important;
  }

  tr > th {
    border-bottom: 1px solid var(--table-border-color) !important;
  }

  tr:first-child > th:first-child {
    border-top-left-radius: 0 !important;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 0.47rem;
  border-right: 1px solid var(--table-border-color) !important;
  border-left: none !important;
  border-top: none !important;
  color: var(--text-color) !important;

  @include breakpoint(sm) {
    padding: 0.5rem;
  }
}

.ant-table-thead > tr > th {
  @include breakpoint(sm) {
    padding: 1rem 0.5rem;
  }
}

.ant-card {
  background: var(--background-color);
}

.ant-table-wrapper {
	background: var(--background-color);
  border: 1px solid var(--table-border-color);
  border-radius: 0.3rem;
}

.table {
  &-member {
    .ant-table-thead {
      th {
        border-top: 1px solid var(--table-border-color) !important;
        border-bottom: 2px solid var(--table-border-color) !important;
      }
    }

    tr {
      height: 5.125rem;
      td,
      th {
        vertical-align: middle;

        &:first-child {
          width: 9rem;

          @include breakpoint(sm) {
            padding: 0.5rem;
          }
        }

        &:nth-child(2) {
          width: 23%;
        }

        &:nth-child(3) {
          width: 17%;
        }

        &:nth-child(4) {
          width: 35%;
        }

        &:nth-child(6) {
          width: 10rem;
        }
      }
    }
  }

  &-portfolio {
    .ant-table-thead {
      th {
        height: 5.125rem;
        border-top: 1px solid var(--table-border-color) !important;
        border-bottom: 2px solid var(--table-border-color) !important;
      }
    }

    tr {
      height: 5.125rem;
      td,
      th {
        vertical-align: middle;

        &:first-child {
          width: 9rem;

          @include breakpoint(sm) {
            padding: 0.5rem;
          }
        }

        &:nth-child(2) {
          width: 40%;
        }

        &:nth-child(3) {
          width: 10%;
        }

        &:nth-child(4) {
          width: 10%;
        }

        &:nth-child(4) {
          width: 10%;
        }

        &:nth-child(6),
        &:nth-child(7) {
          width: 10rem;
        }
      }
    }
  }
}

.ant-table-pagination.ant-pagination {
  width: 100%;
  margin: 0 0px;
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  background-color: var(--background-color-layout);
  float: none;
}

.ant-table {
  margin: 1.9rem 1.9rem;
}

.ant-table-header-column {
  font-weight:bold;
}
