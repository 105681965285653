@import '../../styles/abstracts/mixins';

.ant-layout-content {
  box-shadow: inset 0 0 7px -4px var(--background-color);
  min-height: auto !important;
  padding-top: 5px !important;
}

.logo {
  display: block;
  text-align: center;
  margin: 0;
  background: none;
  border: none;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid var(--layout-color-logo);

  @include breakpoint(sm) {
    min-height: 6.4rem;
    display: flex;
    align-items: center;
    padding: 0;
  }

  img {
    width: 62px;
    transition: all .3s ease;
    margin: 5px;

    @include breakpoint(sm) {
      width: 4rem;
    }
  }
}

.ant-layout-sider-collapsed {
  .logo {
    min-height: 6.4rem;
    display: flex;
    align-items: center;
    @include breakpoint(sm) {
      width: 50px !important;
      margin: 0 0 0 0 !important;
    }
  }

  img {
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.layout {
	color: var(--text-color);

	.ant-layout-sider {
    background: var(--background-color) !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);

    @include breakpoint(sm) {
      flex: 0 0 5rem !important;
      max-width: 5rem !important;
      min-width: 5rem !important;
      width: 5rem !important;
      overflow: hidden;
    }
	}

	.ant-menu-inline {
		border-right: none !important;
	}

	&__menu {
		color: inherit !important;
		border-right: none !important;
	}

	&__menu-item {
		display: flex !important;
    align-items: center;
    color: var(--layout-color-menu-item);

    i {
      color: var(--layout-color-menu-item);
    }

		a {
			color: inherit;
		}
	}

	&__icon-box {
    margin-right: 1rem;
  }

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
    background: var(--base-white) !important;
		font-size: 1.5rem;
		color: var(--text-color);
		padding: 0 4rem !important;
		height: auto !important;

    @include breakpoint(sm) {
      padding: 0 1rem !important;
    }

    .anticon {
      @include breakpoint(sm) {
        display: none;
      }
    }
	}
}

.ant-menu-item {
  width: 93% !important;
  margin-left: auto !important;
  margin-right: auto !important;
   @include breakpoint(sm) {
      width: 50px !important;
      margin: 7px 0 3px 0 !important;
      margin-left: -10px !important;
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item-active {
  border-radius: 5px;
  color: var(--background-color-sidebar) !important;
  background-color: var(--disabled-button-background-color) !important;
  &:hover {
    div > i {
      color: var(--background-color) !important;
    }
  }
}

.ant-menu-item-selected {
  div > i {
    color: var(--background-color-sidebar) !important;
  }
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after{
  display: none;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
  margin: 3px auto 0 auto !important;
  padding: 0.7rem 1rem !important;
  height: auto !important;
}

.ant-menu-item .layout__icon-box {
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-menu-item > span {
  padding-top: 4px !important;
}

.ant-btn-primary {
  background-color: var(--base-shakespeare) !important;
  border: 1px solid var(--base-shakespeare) !important;
}

.ant-btn {
  padding: 0 10px !important;
}

.ant-switch {
  background-color: var(--base-shakespeare) !important;
}

.ant-btn-danger {
  color: var(--base-white) !important;
  background-color: var(--base-mandy) !important;
  border-color: var(--base-mandy) !important;
}

.ant-tag-green {
  color: var(--base-lima) !important;
  background: var(--disabled-button-background-color) !important;
  border-color: var(--base-lima) !important;
}

.btn-success {
  background: rgba(82, 196, 26, 0.1) !important;
  color: var(--base-lima) !important;
  border-color: var(--base-lima) !important;

  &:hover {
    background: rgba(82, 196, 26, 0.8) !important;
    color: var(--background-color) !important;
  }
} 

.ant-input {
  border: 1px solid var(--card-border-color) !important;
}

.ant-card-bordered {
  border: none;
}

.ant-menu-item > i {
  margin-left: -6px !important;
}

#root > section > aside > div > ul > li {
  height: 40px !important;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 2rem !important;
}

.user__table .ant-table-body {
  border-top: 1px solid var(--card-border-color) !important;
  border-radius: 0 3px 0 0;
}

/**
 * override some styles comming from antd
 */

.ant-layout {
  width: 100vw;
  height: 100vh;
  background: var(--background-color-layout);
}

.ant-layout-header {
  padding: 0 3rem;
  background: var(--background-color-header) !important;
}

.layout {
  .ant-menu-inline {
    background: var(--background-color-sidebar) !important;
  }
}

.ant-layout-sider,
.ant-menu {
  background: var(--background-color-sidebar) !important;
}

.ant-layout-sider-children {
  background: var(--background-color-sidebar) !important;
}

.ant-table-body {
  background: var(--background-color-header) !important;
}

.ant-table-thead > tr > th {
  background: var(--background-color-header) !important;
  color: var(--text-color) !important;
}

.ant-menu:not(.ant-menu-horizontal) {
  .ant-menu-item-active {
    background: var(--active-hover-item) !important;
    color: var(--ant-menu-color) !important;
  }
}

.ant-layout-header > i {
  color: rgba(0,0,0,.5);

  &:hover {
    color: rgba(0,0,0,.7);
  }
}

.ant-modal-close-x,
.ant-empty-description {
  color: var(--text-color) !important;
}

.ant-table-placeholder {
  background: var(--background-color) !important;
  color: var(--text-color) !important;
}

.ant-popover-inner-content {
  background: var(--background-color-layout) !important;
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  border-right-color: var(--background-color-layout) !important;
  border-bottom-color: var(--background-color-layout) !important;
}

.ant-popover-message {
  color: var(--text-color) !important;
}
