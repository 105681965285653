.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next{
  margin: 0 -1px 0 0 !important;
  border-radius: 0 !important;

  color: #007bff !important;

  &:hover {
    border: 1px solid #d9d9d9;
  }
}

.ant-pagination-item {
  min-width: 0;

  a {
    color: #007bff !important;
    padding: 0 0.8rem;

    &:hover {
      background-color: #e9ecef;
    }
  }
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  min-width: 0;

  a {
    padding: 0 0.4rem;
  }
}

.ant-pagination-prev,
.ant-pagination-next {
  border-radius: 0 !important;
  border-top-left-radius: .2rem !important;
  border-bottom-left-radius: .2rem !important;
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  border-radius: 0 !important;
  border-top-right-radius: .2rem !important;
  border-bottom-right-radius: .2rem !important;
}

.ant-pagination-item-active {
  border: 1px solid #d9d9d9;
}

.ant-pagination-prev .ant-pagination-item-link {
  border-top-right-radius: .2rem !important;
  border-bottom-right-radius: .2rem !important;
}

.ant-pagination-next .ant-pagination-item-link {
  border-top-left-radius: .2rem !important;
  border-bottom-left-radius: .2rem !important;
}